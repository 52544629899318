@font-face {
    font-family: 'Silkscreen';
    src: local('Silkscreen'), url(./fonts/Silkscreen-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Silkscreen';
    font-weight: 700;
    src: local('Silkscreen'), url(./fonts/Silkscreen-Bold.ttf) format('truetype');
}

.silkscreen {
    font-family: Silkscreen, monospace, sans-serif;
}

@font-face {
    font-family: 'Micro5';
    src: local('Micro5'), url(./fonts/Micro5-Regular.ttf) format('truetype');
}

.micro5 {
    font-family: Micro5, monospace, sans-serif;
}

.hero-title {
    text-shadow: 4px 9px black;
}

.ck-content {
    background-color: inherit !important;
}

.card-cover {
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    object-fit: cover;
}

.ck-input-text, 
.raw-html-embed__buttons-wrapper, 
.raw-html-embed__content-wrapper,
.raw-html-embed {
    background-color: inherit !important;
}